import React from 'react'
import styled from '@emotion/styled'
import { aspectRatio, aspectRatioChild } from '@/utils/helpers'

const Logo = props => (
  <Root {...props}>
    <SVG viewBox="0 0 314.39 112" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path className="cls-2" d="M104.88,12.12h60.6V27.05h-43V46.6h37.65V61.36H122.52V97H104.88Z"/>
      <path className="cls-2" d="M225.56,12H244L215,97H198.26L169.51,12h19l18.46,64.52Z"/>
      <path className="cls-2" d="M306.93,60.2q-7.47,6.24-21.32,6.23H267.92V97H250.27V12h36.5q12.62,0,20.12,6.57t7.5,20.36Q314.39,54,306.93,60.2ZM293.37,29.59q-3.37-2.84-9.45-2.83h-16v25h16q6.07,0,9.45-3.06T296.75,39Q296.75,32.41,293.37,29.59Z"/>
      <polygon className="cls-2" points="0 112 0 56 56 56 0 112"/>
      <polygon className="cls-3" points="56 56 0 56 0 0 56 56"/>
      <path className="cls-4" d="M56,0V56A28,28,0,0,0,56,0Z"/>
      <path className="cls-5" d="M56,0V56A28,28,0,0,0,56,0Z"/>
      <rect className="cls-6" width="56" height="56"/>
    </SVG>
  </Root>
)

const Root = styled.div`
  ${aspectRatio(314.39, 112)};
`

const SVG = styled.svg`
  ${aspectRatioChild};
  .cls-1{
    isolation: isolate;
  }
  .cls-2{
    fill: #333;
  }
  .cls-3{
    fill: gray;
  }
  .cls-4,
  .cls-6 {
    mix-blend-mode: multiply;
  }
  .cls-5 {
    fill: #81c781;
  }
  .cls-6 {
    fill: #4db949;
  }
`

export default Logo
