import React from 'react'
import MuiButton from '@material-ui/core/Button'
import ThemeConsumer from '@/hoc/ThemeConsumer'
import WithStylesProps from '@/hoc/WithStylesProps'
import { fade } from '@material-ui/core/styles/colorManipulator'
import { fonts } from '@/utils/preset'
import { getThemeColor } from '@/utils/themes'

const TextButton = props => {
  const { theme, type, classes, children, className, active, ...other } = props
  return (
    <MuiButton
      className={className}
      classes={classes}
      {...other}
    >
      { children }
    </MuiButton>
  )
}

const getStyles = (props) => {
  let styles = {
    fontSize: props.size === 'small'
      ? 12
      : 14
  }
  const color = props.color || 'text'
  styles.color = getThemeColor(color, props.theme)
  return styles
}

const muiStyles = props => {
  const styles = getStyles(props)
  return {
    root: {
      position: 'relative',
      color: styles.color,
      height: 40,
      padding: '0 12px',
      fontSize: styles.fontSize,
      fontFamily: fonts.base,
      borderRadius: 5,
      '&:hover': {
        textDecoration: 'none',
        backgroundColor: fade(styles.color, 0.1),
        '@media (hover: none)': {
          backgroundColor: 'transparent'
        },
        '&:disabled': {
          backgroundColor: 'transparent'
        }
      },
      '&:disabled': {
        color: styles.color,
        opacity: 0.6
      },
      '&.active': {
        fontWeight: 'bold',
        backgroundColor: fade(styles.color, 0.1)
      }
    }
  }
}

export default ThemeConsumer(WithStylesProps(muiStyles)(TextButton))
