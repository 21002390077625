import React from 'react'
import styled from '@emotion/styled'
import { Location } from '@reach/router'
import route from 'path-match'
import ThemeConsumer from '@/hoc/ThemeConsumer'
import WithStylesProps from '@/hoc/WithStylesProps'
import { tablet, mobile } from '@/utils/media'
import { mq } from '@/utils/helpers'
import TextButton from '@/components/common/TextButton'
import { Link } from 'gatsby'
import LinkButton2 from '@/components/common/LinkButton2'
import * as MenuItem from '@/components/common/MenuItem'

class Nav extends React.Component {
  constructor (props) {
    super(props)
    const state = {}
    props.routes.main.filter(route => {
      return route.childLists
    }).forEach(route => {
      state[route.path] = null
    })
    this.state = state
  }
  handleMenuClick = path => {
    return event => {
      const state = {
        ...this.state
      }
      state[path] = event.currentTarget
      this.setState(state)
    }
  }
  handleMenuClose = path => {
    return event => {
      const state = {
        ...this.state
      }
      state[path] = null
      this.setState(state)
    }
  }
  render () {
    const { theme, routes, classes, ...other } = this.props
    return (
      <Root theme={theme} {...other}>
        <Row>
          <PageList>
            {routes.main.map((item, index) => {
              const match = route({ start: true, end: false })(item.path)
              return (
                <Location key={index}>
                  {({ navigate, location }) => (
                    <PageList_Child>
                      <PageLink
                        className={match(location.pathname) ? 'active' : false}
                        to={item.path}
                        size="small"
                        component={Link}>
                        {item.title}
                      </PageLink>
                    </PageList_Child>
                  )}
                </Location>
              )
            })}
          </PageList>
        </Row>
        <Row>
          {routes.conversion.map((route, index) => (
            <Conversion key={index} to={route.path} component={Link} color={route.color} size="small">{route.title}</Conversion>
          ))}
        </Row>
      </Root>
    )
  }
}

const Root = styled.nav`
  display: flex;
  justify-content: space-between;
  @media ${mq.and(tablet, mobile)} {
    display: none;
  }
`

const Row = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  &:not(:first-of-type) {
    margin-left: 24px;
  }
`

const Conversion = styled(LinkButton2)`
  flex-grow: 0;
  flex-shrink: 0;
  font-weight: bold;
  &:not(:first-of-type) {
    margin-left: 10px;
  }
`

const PageList = styled.ul`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100px;
`

const PageList_Child = styled.li`
  text-align: center;
  margin: 0 4px;
`

const PageLink = styled(TextButton)`
`

const make = props => {
  return {
    ...MenuItem.make(props)
  }
}

export default ThemeConsumer(WithStylesProps(make)(Nav))
