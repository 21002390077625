import React from 'react'
import styled from '@emotion/styled'
import MuiListItem from '@material-ui/core/ListItem'
import ThemeConsumer from '@/hoc/ThemeConsumer'
import WithStylesProps from '@/hoc/WithStylesProps'
import { getThemeColor } from '@/utils/themes'
import MuiWrapper from '@/hoc/MuiWrapper'

const ListItem = props => {
  const { theme, classes, children, className, ...other } = props
  return (
    <Root
      className={className}
      theme={theme}
      classes={{
        ...get(classes)
      }}
      {...other}
    >
      { children }
    </Root>
  )
}

const getStyles = (props) => {
  const color = props.color || 'gray.a'
  let styles = {
    backgroundColor: getThemeColor(color, props.theme),
    color: getThemeColor(color, props.theme, 'contrast'),
    activeColor: getThemeColor(color, props.theme, 'contrast'),
    hoverBackgroundColor: getThemeColor(color, props.theme, 'hover')
  }
  return styles
}

export const make = props => {
  const styles = getStyles(props)
  return {
    ListItem_root: {
      color: styles.color,
      backgroundColor: styles.backgroundColor,
      '&:hover': {
        backgroundColor: styles.hoverBackgroundColor
      }
    }
  }
}

const Root = styled(MuiListItem)`
  &.active {
    font-weight: bold;
    color: ${props => getStyles(props).activeColor};
    &::before {
      content: '';
      display: block;
      position: absolute;
      top: 50%;
      left: 8px;
      transform: translateY(-50%);
      width: 3px;
      height: 3px;
      border-radius: 3px;
      background-color: ${props => getStyles(props).activeColor};
    }
  }
`

export const get = classes => {
  const filteredClasses = {}
  Object.keys(classes).forEach(className => {
    if (className.indexOf('ListItem_') === 0) {
      filteredClasses[className.replace('ListItem_', '')] = classes[className]
    }
  })
  return filteredClasses
}

export default MuiWrapper(ThemeConsumer(
  WithStylesProps(make)(ListItem)
), 'ListItem')
