import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import styled from '@emotion/styled'
import { Global } from '@emotion/core'
import { Location } from '@reach/router'
import { actions as layoutActions } from '@/store/UI/Layout'
import ThemeContext from '@/context/Theme'
import Header from '@/components/app/Header'
import Footer from '@/components/app/Footer'
import themes from '@/utils/themes'
import { layouts } from '@/utils/preset'

class DefaultLayout extends React.Component {
  constructor (props) {
    super(props)
    this.toggleTheme = () => {
      this.setState(state => ({
        theme:
          state.theme === themes.base
            ? themes.light
            : themes.base
      }))
    }
    this.state = {
      theme: themes.base,
      toggleTheme: this.toggleTheme
    }
    this.headRef = React.createRef()
  }
  componentDidMount () {
    this.updateLayoutStates()
  }
  componentDidUpdate (prevProps) {
    if (!this.props.windowInitialized) {
      return
    }
    if (prevProps.windowUpdateState !== 1 && this.props.windowUpdateState === 1) {
      this.updateLayoutStates()
    }
  }
  updateLayoutStates () {
    this.props.setHeadRect(this.headRef.current.getBoundingClientRect())
  }
  render () {
    return (
      <Location>
        {({ location }) => (
          <ThemeContext.Provider value={this.state}>
            <Global styles={{
              body: {
                backgroundColor: this.state.theme.background.base,
                color: this.state.theme.text.base
              }
            }}/>
            <Root>
              <Head ref={this.headRef}>
                <Header />
              </Head>
              <Main>
                {this.props.children}
              </Main>
              <Foot>
                <Footer />
              </Foot>
            </Root>
          </ThemeContext.Provider>
        )}
      </Location>
    )
  }
}

const mapStateToProps = state => {
  return {
    windowHeight: state.UI.Window.fullHeight,
    windowInitialized: state.UI.Window.initialized,
    windowUpdateState: state.UI.Window.updateState,
    ...state.UI.Layout
  }
}

const mapDispatchToProps = dispatch => bindActionCreators({
  ...layoutActions
}, dispatch)

export default connect(
  mapStateToProps, mapDispatchToProps
)(DefaultLayout)

const Root = styled.div``

const Head = styled.div`
`

const Main = styled(layouts.components.Container())`
  position: relative;
  z-index: 1;
  height: 100%;
`

const Foot = styled.div`
  position: relative;
`
