import { css } from '@emotion/core'

function hexToRgb (hex) {
  const shorthandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i
  hex = hex.replace(shorthandRegex, function (m, r, g, b) {
    return r + r + g + g + b + b
  })

  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex)
  return result ? {
    r: parseInt(result[1], 16),
    g: parseInt(result[2], 16),
    b: parseInt(result[3], 16)
  } : null
}

export function rgba (hex, alpha) {
  const rgb = hexToRgb(hex)
  return `rgba(${rgb.r},${rgb.g},${rgb.b},${alpha})`
}

export function modifier (className) {
  return `m_${className}`
}

export function state (className) {
  return `s_${className}`
}

export function dynamicStyles (cb, styles) {
  return cb(styles)
}

export const mq = {
  with: function (type) {
    return type.query
  },
  and: function () {
    const queries = Array.from(arguments).map(type => type.query)
    return `${queries.join(',')}`
  }
}

export function px (val) {
  return `${val}px`
}

export function matchMedia (mediaType) {
  return function () {
    return Array.from(arguments).map(type => type.key).indexOf(mediaType) !== -1
  }
}

export function placeholder (color) {
  return css`
    &::placeholder {
      color: ${color};
    }

    &:placeholder-shown {
      color: ${color};
    }

    &::-webkit-input-placeholder {
      color: ${color};
    }

    &:-moz-placeholder {
      color: ${color};
      opacity: 1;
    }

    &::-moz-placeholder {
      color: ${color};
      opacity: 1;
    }

    &:-ms-input-placeholder {
      color: ${color};
    }
  `
}

export function scrollbar (color, rail = 'transparent') {
  return css`
    &::-webkit-scrollbar {
      -webkit-appearance: none;
    }

    &::-webkit-scrollbar:vertical {
      width: 4px;
    }

    &::-webkit-scrollbar:horizontal {
      height: 4px;
    }

    &::-webkit-scrollbar-thumb {
      background-color: ${color};
      border-radius: 2px;
    }

    &::-webkit-scrollbar-track {
      border-radius: 0;
      background-color: ${rail}; 
      height: 1px;
    }
  `
}

export function truncate (str, n) {
  return (str.length > n) ? str.substr(0, n - 1) + '…' : str
}

export function aspectRatio (w, h, position = 'relative') {
  return css`
    position: ${position};
    &::after {
      content: '';
      display: block;
      padding-top: ${h / w * 100}%;
      width: 0;
    }
  `
}

export function aspectRatioChild () {
  return css`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  `
}

export function nl2br (str) {
  str = str.replace(/\r\n/g, '<br />')
  str = str.replace(/(\n|\r)/g, '<br />')
  return str
}

export default {
  rgba, modifier, state, dynamicStyles, mq, px, matchMedia, placeholder, truncate, scrollbar, aspectRatio, aspectRatioChild, nl2br
}
