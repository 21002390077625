import { darken, lighten } from '@material-ui/core/styles/colorManipulator'

export default {
  base: {
    primary: {
      base: '#4DB949',
      light: '#EDF7ED',
      contrast: {
        base: '#fff'
      },
      hover: {
        base: darken('#4DB949', 0.1)
      },
      contrastHover: {
        base: darken('#fff', 0.1)
      }
    },
    secondary: {
      base: '#222',
      contrast: {
        base: '#fff'
      },
      hover: {
        base: lighten('#222', 0.1)
      },
      contrastHover: {
        base: darken('#fff', 0.1)
      }
    },
    gray: {
      a: '#fff',
      b: '#F7F7F7',
      c: '#808080',
      d: '#666',
      e: '#222222',
      contrast: {
        a: '#666',
        b: '#666',
        c: '#fff',
        d: '#fff',
        e: '#fff'
      },
      hover: {
        a: darken('#fff', 0.1),
        b: lighten('#F7F7F7', 0.1),
        c: lighten('#808080', 0.1),
        d: lighten('#666', 0.1),
        e: lighten('#333222', 0.1)
      },
      contrastHover: {
        a: '#222',
        b: '#222',
        c: darken('#fff', 0.1),
        d: darken('#fff', 0.1),
        e: darken('#fff', 0.1)
      }
    },
    text: {
      base: '#666',
      contrast: {
        base: '#fff'
      },
      hover: {
        base: lighten('#666', 0.1)
      },
      contrastHover: {
        base: darken('#fff', 0.1)
      }
    },
    background: {
      base: '#fff',
      contrast: {
        base: '#666'
      },
      hover: {
        base: darken('#fff', 0.05)
      },
      contrastHover: {
        base: lighten('#666', 0.1)
      }
    },
    line: {
      base: '#E4E4E4',
      contrast: {
        base: '#222'
      },
      hover: {
        base: darken('#E4E4E4', 0.1)
      },
      contrastHover: {
        base: lighten('#222', 0.1)
      }
    },
    error: {
      base: '#F54F27',
      contrast: {
        base: '#fff'
      },
      hover: {
        base: lighten('#F54F27', 0.1)
      },
      contrastHover: {
        base: darken('#fff', 0.1)
      }
    }
  }
}

export const parseThemeColor = keyPath => {
  const array = keyPath.split('.')
  const type = array[0]
  const tone = array.length > 1 ? array[1] : 'base'
  return { type, tone }
}

export const getThemeColor = (keyPath, theme, state) => {
  const { type, tone } = parseThemeColor(keyPath)
  if (!state) return theme[type][tone]
  return theme[type][state][tone]
}
