module.exports = [{
      plugin: require('/opt/build/repo/node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"中小企業や小規模向けのクラウドERPのFVP","short_name":"FVP","start_url":"https://fvc-planner.com/","background_color":"#000","theme_color":"#fff","display":"minimal-ui","icon":"src/images/icon.png"},
    },{
      plugin: require('/opt/build/repo/node_modules/gatsby-plugin-google-tagmanager/gatsby-browser.js'),
      options: {"plugins":[],"id":"GTM-KQ5MJLN","includeInDevelopment":false},
    },{
      plugin: require('/opt/build/repo/node_modules/gatsby-plugin-web-font-loader/gatsby-browser.js'),
      options: {"plugins":[],"google":{"families":["Kameron:400,700"]},"custom":{"families":["Noto+Sans+JP:400,700"],"urls":["https://fonts.googleapis.com/css?family=Noto+Sans+JP:400,700&display=swap&subset=japanese"]}},
    },{
      plugin: require('/opt/build/repo/node_modules/gatsby-plugin-material-ui/gatsby-browser.js'),
      options: {"plugins":[],"theme":{"palette":{"background":{"default":"transparent"}},"typography":{"useNextVariants":true}}},
    },{
      plugin: require('/opt/build/repo/node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[],"maxWidth":1080},
    },{
      plugin: require('/opt/build/repo/node_modules/gatsby-plugin-catch-links/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('/opt/build/repo/node_modules/gatsby-plugin-netlify-cms/gatsby-browser.js'),
      options: {"plugins":[],"modulePath":"/opt/build/repo/src/cms/cms.js"},
    },{
      plugin: require('/opt/build/repo/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
