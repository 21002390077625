import React from 'react'
import MuiButton from '@material-ui/core/Button'
import ThemeConsumer from '@/hoc/ThemeConsumer'
import WithStylesProps from '@/hoc/WithStylesProps'
import { fonts } from '@/utils/preset'
import { getThemeColor } from '@/utils/themes'

const Button = props => {
  const { theme, classes, backgroundAlpha, children, className, color, ...other } = props
  return (
    <MuiButton
      className={className}
      variant="contained"
      classes={classes}
      {...other}
    >
      { children }
    </MuiButton>
  )
}

const getStyles = (props) => {
  const color = props.color || 'text'
  return {
    backgroundColor: getThemeColor(color, props.theme),
    color: getThemeColor(color, props.theme, 'contrast'),
    hoverBackgroundColor: getThemeColor(color, props.theme, 'hover')
  }
}

const styles = props => {
  const styles = getStyles(props)
  return {
    root: {
      backgroundColor: styles.backgroundColor,
      color: styles.color,
      borderRadius: 5,
      height: 70,
      padding: '0 16px',
      fontSize: 16,
      fontFamily: fonts.base,
      boxShadow: 'none',
      '&:hover': {
        textDecoration: 'none',
        backgroundColor: styles.hoverBackgroundColor,
        boxShadow: 'none',
        '@media (hover: none)': {
          backgroundColor: styles.backgroundColor
        },
        '&:disabled': {
          backgroundColor: 'transparent'
        }
      },
      '&:disabled': {
        backgroundColor: styles.backgroundColor,
        color: styles.color,
        opacity: 0.6
      }
    }
  }
}

export default ThemeConsumer(WithStylesProps(styles)(Button))
