import React from 'react'
import { css } from '@emotion/core'
import MuiButton from '@material-ui/core/Button'
import ThemeConsumer from '@/hoc/ThemeConsumer'
import WithStylesProps from '@/hoc/WithStylesProps'
import { getThemeColor } from '@/utils/themes'
import { fonts } from '@/utils/preset'
import { desktop, laptop, tabletL, tabletS, mobile } from '@/utils/media'
import { mq } from '@/utils/helpers'
import Logo from './Logo'

const LogoButton = props => {
  const { theme, classes, children, className, color, ...other } = props
  return (
    <MuiButton
      className={className}
      variant="contained"
      classes={classes}
      css={styles.Root}
      {...other}
    >
      <Logo css={styles.Logo}/>
    </MuiButton>
  )
}

const getStyles = (props) => {
  const color = props.color || 'text'
  return {
    backgroundColor: getThemeColor(color, props.theme, 'contrast'),
    color: getThemeColor(color, props.theme),
    hoverBackgroundColor: getThemeColor(color, props.theme, 'contrastHover')
  }
}

const muiStyles = props => {
  const styles = getStyles(props)
  return {
    root: {
      backgroundColor: styles.backgroundColor,
      color: styles.color,
      borderRadius: 5,
      padding: 0,
      fontSize: 0,
      fontFamily: fonts.base,
      boxShadow: 'none',
      justifyContent: 'center',
      alignItems: 'center',
      '&:hover': {
        textDecoration: 'none',
        backgroundColor: styles.hoverBackgroundColor,
        boxShadow: 'none',
        '@media (hover: none)': {
          backgroundColor: styles.backgroundColor
        },
        '&:disabled': {
          backgroundColor: styles.backgroundColor
        }
      },
      '&:disabled': {
        backgroundColor: styles.backgroundColor,
        color: styles.color,
        opacity: 0.6
      }
    }
  }
}

const styles = {
  Root: css`
    width: 132px;
    display: flex;
    justify-content: center;
    align-items: center;
    @media ${mq.and(desktop, laptop)} {
      padding: 12px 20px;
    }
    @media ${mq.and(tabletL, tabletS, mobile)} {
      width: 100px;
      padding: 16px;
    }
  `,
  Logo: css`
    width: 100%;
  `
}

export default ThemeConsumer(WithStylesProps(muiStyles)(LogoButton))
