import React from 'react'
import MuiButton from '@material-ui/core/Button'
import ThemeConsumer from '@/hoc/ThemeConsumer'
import WithStylesProps from '@/hoc/WithStylesProps'
import { getThemeColor } from '@/utils/themes'
import { fonts } from '@/utils/preset'

const LinkButton2 = props => {
  const { theme, type, classes, children, className, icon, ...other } = props
  return (
    <MuiButton
      className={className}
      variant="contained"
      classes={classes}
      {...other}
    >
      { children }
    </MuiButton>
  )
}

const getStyles = (props) => {
  const color = props.color || 'primary'
  const styles = {
    fontSize: props.size === 'small'
      ? 12
      : 16,
    backgroundColor: getThemeColor(color, props.theme),
    color: getThemeColor(color, props.theme, 'contrast'),
    hoverBackgroundColor: getThemeColor(color, props.theme, 'hover')
  }
  return styles
}

const muiStyles = props => {
  const styles = getStyles(props)
  return {
    root: {
      backgroundColor: styles.backgroundColor,
      padding: '0 20px',
      color: styles.color,
      height: 50,
      fontSize: styles.fontSize,
      fontFamily: fonts.base,
      boxShadow: 'none',
      justifyContent: 'center',
      alignItems: 'center',
      borderRadius: 5,
      '&:hover': {
        textDecoration: 'none',
        backgroundColor: styles.hoverBackgroundColor,
        boxShadow: 'none',
        '@media (hover: none)': {
          backgroundColor: styles.backgroundColor
        },
        '&:disabled': {
          backgroundColor: styles.backgroundColor
        }
      },
      '&:disabled': {
        backgroundColor: styles.backgroundColor,
        color: styles.color,
        opacity: 0.6
      }
    },
    label: {
      position: 'relative'
    }
  }
}

export default ThemeConsumer(WithStylesProps(muiStyles)(LinkButton2))
