import styled from '@emotion/styled'
import { tablet, mobile, mobileS } from './/media'
import { mq } from './/helpers'

export const defs = {
  maxWidth: 1160,
  pad: {
    base: 80,
    tablet: 40,
    mobile: 24,
    mobileS: 16
  },
  contentMaxWidth: {
    base: 768,
    small: 500
  }
}

export const components = {
  FullWidth: styled.div`
    width: 100vw;
    margin-left: calc((100vw - 100%) / 2 * -1);
  `,
  Container: pad => {
    if (!pad) {
      pad = {
        base: { top: 0, bottom: 0 },
        tablet: { top: 0, bottom: 0 },
        mobile: { top: 0, bottom: 0 },
        mobileS: { top: 0, bottom: 0 }
      }
    }
    return styled.div`
      box-sizing: content-box;
      padding: ${pad.base.top}px ${defs.pad.base}px ${pad.base.bottom}px;
      max-width: ${defs.maxWidth}px;
      margin: 0 auto;
      @media ${mq.and(tablet)} {
        padding: ${pad.tablet.top}px ${defs.pad.tablet}px ${pad.tablet.bottom}px;
      }
      @media ${mq.and(mobile)} {
        max-width: ${defs.contentMaxWidth.small}px;
        padding: ${pad.mobile.top}px ${defs.pad.mobile}px ${pad.mobile.bottom}px;
      }
      @media ${mq.and(mobileS)} {
        max-width: ${defs.contentMaxWidth.small}px;
        padding: ${pad.mobileS.top}px ${defs.pad.mobileS}px ${pad.mobileS.bottom}px;
      }
    `
  }
}

export default {
  defs, components
}
