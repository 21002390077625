import React, { Fragment } from 'react'
import styled from '@emotion/styled'
import { css } from '@emotion/core'
import { StaticQuery, graphql, Link } from 'gatsby'
import { layouts } from '@/utils/preset'
import { desktop, laptop, tablet, mobile } from '@/utils/media'
import { mq } from '@/utils/helpers'
import Nav from './Nav'
import NavMenu from './NavMenu'
import LogoButton from './LogoButton'

const Header = () => (
  <Root>
    <Container>
      <LogoButton to="/" component={Link} css={styles.LogoButton}/>
      <Spacer />
      <StaticQuery
        query={query}
        render={ data => {
          const routes = data.routes.edges[0].node
          return (
            <Fragment>
              <Nav routes={routes} css={styles.Nav}></Nav>
              <NavMenu routes={routes} css={styles.NavMenu}/>
            </Fragment>
          )
        }}
      />
    </Container>
  </Root>
)

export default Header

const Root = styled.header`
  display: flex;
  color: white;
  position: relative;
  @media ${mq.and(desktop, laptop)} {
    z-index: 1;
    padding: 0 20px;
  }
  justify-content: space-between;
`

const Container = styled.div`
  @media ${mq.and(desktop)} {
    max-width: ${layouts.defs.maxWidth + layouts.defs.pad.base * 2}px;
  }
  margin: 0 auto;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  @media ${mq.and(tablet, mobile)} {
    height: 50px;
    justify-content: flex-start;
  }
`

const Spacer = styled.div`
  flex-shrink: 1;
  flex-grow: 1;
  @media ${mq.and(tablet, mobile)} {
    flex-shrink: 0;
    flex-grow: 0;
    width: 0;
  }
`

const styles = {
  LogoButton: css`
  `,
  Nav: css`
    flex-shrink: 0;
    flex-grow: 0;
  `,
  NavMenu: css`
    @media ${mq.and(desktop, laptop)} {
      display: none;
    }
  `
}

const query = graphql`
  query HeaderQuery {
    routes: allRouteYaml {
      edges {
        node {
          main {
            title
            path
          }
          conversion {
            title
            path
            color
          }
          others {
            title
            childLists {
              title
              path
            }
          }
        }
      }
    }
  }
`
