import React from 'react'
import styled from '@emotion/styled'
import ThemeConsumer from '@/hoc/ThemeConsumer'
import { mobileM, mobileS } from '@/utils/media'
import { mq } from '@/utils/helpers'
import { fonts } from '@/utils/preset'
import { getThemeColor } from '@/utils/themes'

const Typography = props => {
  const { lang = 'base', variant, component, theme, className, color, type, children, ...other } = props
  let Root = styles[lang][variant]
  Root = component ? Root.withComponent(component) : Root
  return (
    <Root className={className} theme={theme} color={color} type={type} {...other}>{ children }</Root>
  )
}
export default ThemeConsumer(Typography)

const Base = styled.div`
  color: ${props => {
    if (props.color) {
      return getThemeColor(props.color, props.theme)
    } else {
      return 'currentColor'
    }
  }};
  overflow-wrap: break-word;
`

const styles = {
  base: {
    h1: styled(Base)`
      font-size: 32px;
      line-height: 1.5;
      @media ${mq.and(mobileM, mobileS)} {
        font-size: 24px;
      }
    `.withComponent('h1'),
    h2: styled(Base)`
      font-size: 30px;
      line-height: 1.5;
      @media ${mq.and(mobileM, mobileS)} {
        font-size: 22px;
      }
    `.withComponent('h2'),
    h3: styled(Base)`
      font-size: 28px;
      line-height: 1.5;
      @media ${mq.and(mobileM, mobileS)} {
        font-size: 20px;
      }
    `.withComponent('h3'),
    h4: styled(Base)`
      font-size: 24px;
      line-height: 1.5;
      @media ${mq.and(mobileM, mobileS)} {
        font-size: 18px;
      }
    `.withComponent('h4'),
    h5: styled(Base)`
      font-size: 20px;
      line-height: 1.5;
      @media ${mq.and(mobileM, mobileS)} {
        font-size: 16px;
      }
    `.withComponent('h5'),
    h6: styled(Base)`
      font-size: 18px;
      line-height: 1.5;
      @media ${mq.and(mobileM, mobileS)} {
        font-size: 14px;
      }
    `.withComponent('h6'),
    body1: styled(Base)`
      font-size: 16px;
      line-height: 1.75;
      @media ${mq.and(mobileM, mobileS)} {
        font-size: 14px;
      }
    `.withComponent('p'),
    body2: styled(Base)`
      font-size: 14px;
      line-height: 1.75;
      @media ${mq.and(mobileM, mobileS)} {
        font-size: 14px;
      }
    `.withComponent('p'),
    body3: styled(Base)`
      font-size: 12px;
      line-height: 1.75;
      @media ${mq.and(mobileM, mobileS)} {
        font-size: 12px;
      }
    `.withComponent('p'),
    caption1: styled(Base)`
      font-size: 14px;
      line-height: 1.5;
      @media ${mq.and(mobileM, mobileS)} {
        font-size: 12px;
      }
    `.withComponent('p'),
    caption2: styled(Base)`
      font-size: 12px;
      line-height: 1.5;
      @media ${mq.and(mobileM, mobileS)} {
        font-size: 10px;
      }
    `.withComponent('p')
  },
  en: {
    h1: styled(Base)`
      font-family: ${fonts.en};
      font-size: 80px;
      line-height: 1;
      @media ${mq.and(mobileM, mobileS)} {
        font-size: 66px;
      }
      visibility: hidden;
      html.wf-kameron-n4-active &,
      html.wf-kameron-n4-inactive & {
        visibility: visible;
      }
    `.withComponent('h1'),
    h3: styled(Base)`
      font-family: ${fonts.en};
      font-size: 32px;
      line-height: 1.312532;
      @media ${mq.and(mobileM, mobileS)} {
        font-size: 28px;
      }
      visibility: hidden;
      html.wf-kameron-n7-active &,
      html.wf-kameron-n7-inactive & {
        visibility: visible;
      }
    `.withComponent('h3'),
    h4: styled(Base)`
      font-family: ${fonts.en};
      font-size: 22px;
      line-height: 1.5;
      @media ${mq.and(mobileM, mobileS)} {
        font-size: 18px;
      }
      visibility: hidden;
      html.wf-kameron-n7-active &,
      html.wf-kameron-n7-inactive & {
        visibility: visible;
      }
    `.withComponent('h4'),
    h5: styled(Base)`
      font-family: ${fonts.en};
      font-size: 18px;
      line-height: 1.5;
      @media ${mq.and(mobileM, mobileS)} {
        font-size: 16px;
      }
      visibility: hidden;
      html.wf-kameron-n7-active &,
      html.wf-kameron-n7-inactive & {
        visibility: visible;
      }
    `.withComponent('h5'),
    caption: styled(Base)`
      font-family: ${fonts.en};
      font-size: 12px;
      line-height: 1.5;
      @media ${mq.and(mobileM, mobileS)} {
        font-size: 10px;
      }
      visibility: hidden;
      html.wf-kameron-n7-active &,
      html.wf-kameron-n7-inactive & {
        visibility: visible;
      }
    `.withComponent('p'),
    subtitle: styled(Base)`
      font-family: ${fonts.en};
      font-size: 15px;
      line-height: 1.5;
      @media ${mq.and(mobileM, mobileS)} {
        font-size: 14px;
      }
      visibility: hidden;
      html.wf-kameron-n7-active &,
      html.wf-kameron-n7-inactive & {
        visibility: visible;
      }
    `.withComponent('p')
  }
}
