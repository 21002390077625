// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-templates-news-list-template-js": () => import("/opt/build/repo/src/templates/news-list-template.js" /* webpackChunkName: "component---src-templates-news-list-template-js" */),
  "component---src-templates-blog-list-template-js": () => import("/opt/build/repo/src/templates/blog-list-template.js" /* webpackChunkName: "component---src-templates-blog-list-template-js" */),
  "component---src-templates-blog-template-js": () => import("/opt/build/repo/src/templates/blog-template.js" /* webpackChunkName: "component---src-templates-blog-template-js" */),
  "component---src-templates-news-template-js": () => import("/opt/build/repo/src/templates/news-template.js" /* webpackChunkName: "component---src-templates-news-template-js" */),
  "component---src-templates-page-template-js": () => import("/opt/build/repo/src/templates/page-template.js" /* webpackChunkName: "component---src-templates-page-template-js" */),
  "component---src-pages-404-js": () => import("/opt/build/repo/src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-index-js": () => import("/opt/build/repo/src/pages/about/index.js" /* webpackChunkName: "component---src-pages-about-index-js" */),
  "component---src-pages-contact-index-js": () => import("/opt/build/repo/src/pages/contact/index.js" /* webpackChunkName: "component---src-pages-contact-index-js" */),
  "component---src-pages-document-index-js": () => import("/opt/build/repo/src/pages/document/index.js" /* webpackChunkName: "component---src-pages-document-index-js" */),
  "component---src-pages-faq-index-js": () => import("/opt/build/repo/src/pages/faq/index.js" /* webpackChunkName: "component---src-pages-faq-index-js" */),
  "component---src-pages-feature-index-js": () => import("/opt/build/repo/src/pages/feature/index.js" /* webpackChunkName: "component---src-pages-feature-index-js" */),
  "component---src-pages-index-js": () => import("/opt/build/repo/src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-pricing-index-js": () => import("/opt/build/repo/src/pages/pricing/index.js" /* webpackChunkName: "component---src-pages-pricing-index-js" */),
  "component---src-pages-thanks-document-js": () => import("/opt/build/repo/src/pages/thanks-document.js" /* webpackChunkName: "component---src-pages-thanks-document-js" */),
  "component---src-pages-thanks-download-js": () => import("/opt/build/repo/src/pages/thanks-download.js" /* webpackChunkName: "component---src-pages-thanks-download-js" */),
  "component---src-pages-thanks-js": () => import("/opt/build/repo/src/pages/thanks.js" /* webpackChunkName: "component---src-pages-thanks-js" */)
}

