import React from 'react'
import styled from '@emotion/styled'
import { css } from '@emotion/core'
import { StaticQuery, graphql, Link } from 'gatsby'
import ThemeConsumer from '@/hoc/ThemeConsumer'
import { layouts } from '@/utils/preset'
import { desktop, laptop, tabletL, tabletS, mobile, mobileS } from '@/utils/media'
import { mq } from '@/utils/helpers'
import Typography from '@/components/common/Typography'
import LogoSVG from '@/components/app/Logo'

const Footer = ({ theme }) => (
  <StaticQuery
    query={query}
    render={data => {
      const routes = data.routes.edges[0].node
      return (
        <Root theme={theme}>
          <Main>
            <Container>
              <Logo theme={theme}>
                <LogoSVG css={styles.LogoSVG} />
              </Logo>
              <Sitemap>
                <Sitemap_Col>
                  <Sitemap_Row>
                    <PageLink theme={theme} variant="caption1" component={Link} to="/">
                      <PageLink_Arrow width="8" height="9" viewBox="0 0 8 9" xmlns="http://www.w3.org/2000/svg">
                        <path d="M7.5 4.33013L1.03273e-07 8.66026L0 8.94366e-08L7.5 4.33013Z" />
                      </PageLink_Arrow>
                      サイトトップ
                    </PageLink>
                  </Sitemap_Row>
                  {routes.main.slice(0, 4).map((route, index) => (
                    <Sitemap_Row key={index}>
                      <PageLink theme={theme} variant="caption1" component={Link} to={route.path}>
                        <PageLink_Arrow width="8" height="9" viewBox="0 0 8 9" xmlns="http://www.w3.org/2000/svg">
                          <path d="M7.5 4.33013L1.03273e-07 8.66026L0 8.94366e-08L7.5 4.33013Z" />
                        </PageLink_Arrow>
                        {route.title}
                      </PageLink>
                      {route.childLists && (
                        <PageChildren>
                          {route.childLists.map((route, index) => (
                            <PageChildLink key={index} theme={theme} variant="caption1" component={Link} to={route.path}>{route.title}</PageChildLink>
                          ))}
                        </PageChildren>
                      )}
                    </Sitemap_Row>
                  ))}
                </Sitemap_Col>
                <Sitemap_Col>
                  {routes.main.slice(4).map((route, index) => (
                    <Sitemap_Row key={index}>
                      <PageLink theme={theme} variant="caption1" component={Link} to={route.path}>
                        <PageLink_Arrow width="8" height="9" viewBox="0 0 8 9" xmlns="http://www.w3.org/2000/svg">
                          <path d="M7.5 4.33013L1.03273e-07 8.66026L0 8.94366e-08L7.5 4.33013Z" />
                        </PageLink_Arrow>
                        {route.title}
                      </PageLink>
                      {route.childLists && (
                        <PageChildren>
                          {route.childLists.map((route, index) => (
                            <PageChildLink key={index} theme={theme} variant="caption1" component={Link} to={route.path}>{route.title}</PageChildLink>
                          ))}
                        </PageChildren>
                      )}
                    </Sitemap_Row>
                  ))}
                  {routes.conversion.map((route, index) => (
                    <Sitemap_Row key={index}>
                      <PageLink theme={theme} variant="caption1" component={Link} to={route.path}>
                        <PageLink_Arrow width="8" height="9" viewBox="0 0 8 9" xmlns="http://www.w3.org/2000/svg">
                          <path d="M7.5 4.33013L1.03273e-07 8.66026L0 8.94366e-08L7.5 4.33013Z" />
                        </PageLink_Arrow>
                        {route.title}
                      </PageLink>
                      {route.childLists && (
                        <PageChildren>
                          {route.childLists.map((route, index) => (
                            <PageChildLink key={index} theme={theme} variant="caption1" component={Link} to={route.path}>{route.title}</PageChildLink>
                          ))}
                        </PageChildren>
                      )}
                    </Sitemap_Row>
                  ))}
                </Sitemap_Col>
                <Sitemap_Col>
                  {routes.others.map((route, index) => (
                    route.childLists.map((route, index) => (
                      <Sitemap_Row key={index}>
                        <PageLink key={index} theme={theme} variant="caption1" component={Link} to={route.path}>
                          <PageLink_Arrow width="8" height="9" viewBox="0 0 8 9" xmlns="http://www.w3.org/2000/svg">
                            <path d="M7.5 4.33013L1.03273e-07 8.66026L0 8.94366e-08L7.5 4.33013Z" />
                          </PageLink_Arrow>
                          {route.title}
                        </PageLink>
                      </Sitemap_Row>
                    ))
                  ))}
                  {routes.external && routes.external.map((route, index) => (
                    <Sitemap_Row key={index}>
                      <PageLink noLink theme={theme} variant="caption1">{route.title}</PageLink>
                      {route.childLists && (
                        <PageChildren>
                          {route.childLists.map((route, index) => (
                            <PageChildLink key={index} theme={theme} variant="caption1" component="a" href={route.path} target="_blank" rel="noopener noreferrer">{route.title}</PageChildLink>
                          ))}
                        </PageChildren>
                      )}
                    </Sitemap_Row>
                  ))}
                </Sitemap_Col>
              </Sitemap>
            </Container>
          </Main>
          <Foot theme={theme}>
            <CopyRight theme={theme}>
              <CopyRight_Container><Typography variant="body3">Copyright © 2019 FRONT VISION PLANNER All rights reserved.</Typography></CopyRight_Container>
            </CopyRight>
          </Foot>
        </Root>
      )
    }}
  />
)

const Root = styled.div`
  color: ${props => props.theme.gray.contrast.b};
  background-color: ${props => props.theme.gray.b};
`

const Main = styled(layouts.components.Container({
  base: {
    top: 80,
    bottom: 80
  },
  tablet: {
    top: 48,
    bottom: 48
  },
  mobile: {
    top: 48,
    bottom: 48
  },
  mobileS: {
    top: 48,
    bottom: 48
  }
}))`
  position: relative;
`

const Foot = styled.div`
  background-color: ${props => props.theme.primary.base};
  color: ${props => props.theme.primary.contrast.base};
`

const Container = styled.div`
  @media ${mq.and(desktop, laptop, tabletL)} {
    display: flex;
  }
`

const Logo = styled.div`
  @media ${mq.and(desktop, laptop, tabletL)} {
    width: 25%;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 24px;
  }
`

const Sitemap = styled.div`
  display: flex;
  flex-wrap: wrap;
  @media ${mq.and(desktop, laptop, tabletL)} {
    width: 75%;
    flex-grow: 1;
    flex-shrink: 1;
  }
  @media ${mq.and(tabletS, mobile)} {
    margin-top: 16px;
  }
`

const Sitemap_Col = styled.div`
  flex-shrink: 0;
  flex-grow: 0;
  padding-right: 24px;
  width: ${100 / 3}%;
  @media ${mq.and(desktop, laptop, tabletL)} {
    margin-top: -16px;
  }
  @media ${mq.and(mobile)} {
    width: 50%;
    padding-right: 24px;
  }
  @media ${mq.and(mobileS)} {
    width: 100%;
    padding-right: 0;
  }
`

const Sitemap_Row = styled.div`
  margin-top: 16px;
`

const PageLink = styled(Typography)`
  display: flex;
  align-items: baseline;
  transition: color 0.3s ease;
  @media (hover: hover) {
    &:hover {
      color: ${props => props.noLink
        ? 'currentColor'
        : props.theme.gray.contrastHover.b
      };
    }
  }
`
const PageLink_Arrow = styled.svg`
  fill: currentColor;
  margin-right: 8px;
  flex-grow: 0;
  flex-shrink: 0;
`

const PageChildren = styled.ul`
`

const PageChildLink = styled(Typography)`
  margin-top: 16px;
  margin-left: 12px;
  display: block;
  transition: color 0.3s ease;
  font-weight: normal;

  @media (hover: hover) {
    &:hover {
      color: ${props => props.theme.gray.contrastHover.b};
    }
  }

  @media ${mq.and(mobile)} {
    margin-left: 16px;
  }
`

const CopyRight = styled(layouts.components.FullWidth)`
`

const CopyRight_Container = styled(layouts.components.Container())`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 64px;
`

const styles = {
  LogoSVG: css`
    @media ${mq.and(desktop, laptop, tabletL)} {
      width: 100%;
      max-width: 92px;
    }
    @media ${mq.and(tabletS)} {
      width: 68px;
    }
    @media ${mq.and(mobile)} {
      width: 68px;
    }
  `
}

export default ThemeConsumer(Footer)

const query = graphql`
  query FooterQuery {
    routes: allRouteYaml {
      edges {
        node {
          main {
            title
            path
          }
          conversion {
            title
            path
            color
          }
          others {
            title
            childLists {
              title
              path
            }
          }
        }
      }
    }
  }
`
