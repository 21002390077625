import React from 'react'
import PropTypes from 'prop-types'
import { Provider } from 'react-redux'
import store from './src/store'
import './src/iconfont'
import GlobalStyle from '@/components/global/Style'
import WindowManager from '@/components/global/WindowManager'
import InlineScripts from '@/components/head/inline-scripts'
import DefaultLayout from '@/components/layouts/Default'

export const wrapRootElement = ({ element }) => {
  return (
    <Provider store={store}>
      <GlobalStyle />
      <InlineScripts />
      <WindowManager />
      {element}
    </Provider>
  )
}

wrapRootElement.propTypes = {
  element: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ])
}

export const wrapPageElement = ({ element }) => {
  return <DefaultLayout>
    {element}
  </DefaultLayout>
}

export const onRouteUpdate = ({ location }) => {
  store.dispatch({ type: 'app/Route/SET_LOCATION', payload: location })
}

export const onClientEntry = async () => {
  // Object-fit/Object-position polyfill for gatsby-image (IE)
  const testImg = document.createElement(`img`)
  if (
    typeof testImg.style.objectFit === `undefined` ||
    typeof testImg.style.objectPosition === `undefined`
  ) {
    const ObjectFitImages = await import(`object-fit-images`)
    ObjectFitImages.default()
  }
}
