export const mobile = {
  key: 'mobile',
  max: 767,
  query: `(max-width: 767px)`
}
export const mobileS = {
  key: 'mobile',
  max: 374,
  query: `(max-width: 374px)`
}
export const mobileM = {
  key: 'mobile',
  max: 767,
  query: `(max-width: 480px) and (min-width: 375px)`
}
export const mobileL = {
  key: 'mobile',
  max: 767,
  query: `(max-width: 767px) and (min-width: 480px)`
}
export const tablet = {
  key: 'tablet',
  max: 1199,
  min: 768,
  query: `(max-width: 1199px) and (min-width: 768px)`
}
export const tabletL = {
  key: 'tabletL',
  max: 1199,
  min: 1024,
  query: `(max-width: 1199px) and (min-width: 1024px)`
}
export const tabletS = {
  key: 'tabletS',
  max: 1023,
  min: 768,
  query: `(max-width: 1023px) and (min-width: 768px)`
}
export const laptop = {
  key: 'laptop',
  max: 1440,
  min: 1200,
  query: `(max-width: 1440px) and (min-width: 1200px)`
}
export const desktop = {
  key: 'desktop',
  min: 1441,
  query: `(min-width: 1441px)`
}
export default {
  mobileS, mobile, tablet, tabletL, tabletS, laptop, desktop
}
