import MuiMenuItem from '@material-ui/core/MenuItem'
import { fonts } from '@/utils/preset'
import { getThemeColor } from '@/utils/themes'

export default MuiMenuItem

const getColor = (props) => {
  const color = props.color || 'text'
  return getThemeColor(color, props.theme)
}

export const make = (props, options = {}) => {
  const color = getColor(props)
  return {
    MenuItem_root: {
      color: color,
      fontSize: options.fontSize || 14,
      fontFamily: fonts.base
    }
  }
}

export const get = classes => {
  const filteredClasses = {}
  Object.keys(classes).forEach(className => {
    if (className.indexOf('MenuItem_') === 0) {
      filteredClasses[className.replace('MenuItem_', '')] = classes[className]
    }
  })
  return filteredClasses
}
